import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Select, { AsyncCreatableSelect, AsyncSelect } from '../components/Select';
import DatePicker from '../components/DatePicker';
import QuillComponent from '../components/QuillComponent';
import Dropzone from '../components/Dropzone';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
import FormText from '../../../components/bootstrap/forms/FormText';
import useDarkMode from '../../../hooks/useDarkMode';
import { getQueryParams } from '../../../helpers/helpers';
import BoardModule from '../../../modules/bakti/BoardModule';
import TaskModule from '../../../modules/bakti/TaskModule';
import Actions from './Actions';
import { formatBytes } from '../../../helpers/format';
import LoadingOverlay from '../components/LoadingOverlay';
import { readFromLocalStorage } from '../utils';
import { createTask } from '../../../enums/activityTask';

const FormCreateTask = ({
	isOpen,
	setIsOpen,
	title,
	size,
	id,
	onSuccess,
	role,
	getToken,
	detailBoard,
	isAuthenticated,
}) => {
	const { darkModeStatus } = useDarkMode();

	const { board_code: boardCode } = getQueryParams();

	const fileType = readFromLocalStorage('task_attachment');
	const listPriority = readFromLocalStorage('board_priority');
	const listColumn = readFromLocalStorage('board_column');
	const currentUser = readFromLocalStorage('board_user');

	const extentions = fileType?.extentions?.join(', ')?.toUpperCase();
	const limit = formatBytes(fileType?.limit);

	const tagsRef = useRef(null);
	const assigneeRef = useRef(null);

	const [loading, setLoading] = useState(false);
	const [bearerToken, setBearerToken] = useState(null);
	useEffect(() => {
		if (detailBoard?.visibility === 'public' && isAuthenticated) {
			getToken().then((r) => {
				setBearerToken(r);
			});
		}
	}, [detailBoard, getToken, isAuthenticated]);
	const formik = useFormik({
		initialValues: {
			title: '',
			priority: null,
			tags: [],
			status: null,
			assignee: [],
			due_date: null,
			description: '',
			attachment: [],
		},
		enableReinitialize: true,
		validationSchema: Yup.object({
			title: Yup.string().trim().required('Required'),
			priority: Yup.mixed().required('Required'),
			tags: Yup.array().optional(),
			status: Yup.mixed().required('Required'),
			assignee: Yup.array().optional(),
			due_date: Yup.date().nullable().optional(),
			description: Yup.string().optional(),
			attachment: Yup.array().optional(),
		}),
		onReset: () => {
			setIsOpen(false);
		},
		onSubmit: (v, { resetForm }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Do you want to save this?',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Save',
					cancelButtonColor: '#d33',
					cancelButtonText: `Don't Save`,
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const activity_type = createTask.activityType;
						const activity_detail = createTask.generateActivityDetail({
							username: currentUser.name,
							taskName: v.title,
						});
						const newValue = {
							title: v.title,
							priority: JSON.stringify(v.priority),
							tags: v.tags.length ? JSON.stringify(v.tags) : [],
							status: JSON.stringify(v.status),
							assignee: v.assignee.length ? JSON.stringify(v.assignee) : [],
							duedate_at: v.due_date,
							description: v.description,
							reporter: JSON.stringify(currentUser),
							attachment: v.attachment,

							// default
							actor: JSON.stringify(currentUser),
							activity_type,
							activity_detail,
						};
						if (detailBoard?.visibility === 'public' && bearerToken) {
							newValue.bearer_token = bearerToken;
						}
						setLoading(true);

						Actions.createTask(detailBoard?.trans_code, newValue)
							.then(() => {
								resetForm();
								onSuccess();
								formik.resetForm();
							})
							.catch(() => {})
							.finally(() => {
								setLoading(false);
							});
					}
				});
			} catch (err) {
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			}
		},
	});

	return (
		<div>
			<Modal
				setIsOpen={formik.resetForm}
				isOpen={isOpen}
				titleId={id}
				size={size}
				isFocus={false}
				enableEscape={false}
				isStaticBackdrop>
				<ModalHeader setIsOpen={formik.resetForm}>
					<ModalTitle id={id}>{title}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-sm-12 py-1'>
							<FormGroup id='title' label='Task Name'>
								<Input
									autoComplete='off'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.title}
									isValid={formik.isValid}
									isTouched={formik.touched.title}
									invalidFeedback={formik.errors.title}
								/>
							</FormGroup>
						</div>

						<div className='col-sm-12 col-lg-6 py-1'>
							<FormGroup id='priority' label='Priority'>
								<Select
									placeholder='Select one'
									options={listPriority}
									defaultValue={formik.values.priority}
									value={formik.values.priority}
									onChange={(e) => formik.setFieldValue('priority', e)}
									isValid={!formik.errors.priority}
									isSubmitted={!!formik.errors.priority}
									invalidFeedback={formik.errors.priority}
								/>
							</FormGroup>
						</div>

						<div className='col-sm-12 col-lg-6 py-1'>
							<FormGroup id='tags' label='Tags (Optional)'>
								<AsyncCreatableSelect
									ref={tagsRef}
									placeholder='Select Tags'
									loadOptions={(e) => TaskModule.loadOptionsAllTags(e, boardCode)}
									cacheOptions
									defaultOptions
									defaultValue={formik.values.tags}
									value={formik.values.tags}
									onChange={(e) => formik.setFieldValue('tags', e)}
									isMulti
								/>
							</FormGroup>
						</div>

						<div className='col-sm-12 col-lg-4 py-1'>
							<FormGroup id='status' label='Status'>
								<Select
									placeholder='Select one'
									options={listColumn}
									defaultValue={formik.values.status}
									value={formik.values.status}
									onChange={(e) => formik.setFieldValue('status', e)}
									isValid={!formik.errors.status}
									isSubmitted={!!formik.errors.status}
									invalidFeedback={formik.errors.status}
								/>
							</FormGroup>
						</div>

						<div className='col-sm-12 col-lg-5 py-1'>
							<FormGroup id='assignee' label='Assignee'>
								<AsyncSelect
									ref={assigneeRef}
									placeholder='Type here..'
									loadOptions={() => BoardModule.loadOptionsMember(boardCode)}
									cacheOptions
									defaultOptions
									defaultValue={formik.values.assignee}
									value={formik.values.assignee}
									onChange={(e) => formik.setFieldValue('assignee', e)}
									isMulti
									isValid={!formik.errors.assignee}
									isSubmitted={!!formik.errors.assignee}
									invalidFeedback={formik.errors.assignee}
								/>
							</FormGroup>
						</div>

						<div className='col-sm-12 col-lg-3 py-1'>
							<FormGroup id='due_date' label='Due Date'>
								<DatePicker
									placeholder='YYYY-MM-DD'
									dateFormat='yyyy-MM-dd'
									minDate={new Date()}
									value={formik.values.due_date}
									onChange={(value) => formik.setFieldValue('due_date', value)}
									isSubmitted={!!formik.errors.due_date}
									invalidFeedback={formik.errors.due_date}
									autoComplete='off'
								/>
							</FormGroup>
						</div>

						<div className='col-sm-12 py-1'>
							<Label>Description (Optional)</Label>
							<QuillComponent
								value={formik.values.description}
								onChange={(e) => formik.setFieldValue('description', e)}
							/>
						</div>

						<div className='col-sm-12 py-1'>
							<div className='py-1'>
								<Label className='fw-bold'>Attachment</Label>
							</div>
							<div className='mt-n3'>
								<FormText>
									({`Max size ${limit} with ${extentions} Extention`})
								</FormText>
							</div>
							<Dropzone
								showAddButton
								maxLimit={fileType?.limit}
								mimeTypes={fileType?.mimeTypes}
								onUpdate={(e) => formik.setFieldValue('attachment', e.attachment)}
								role={role}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						isLink
						color='info'
						type='button'
						onClick={formik.resetForm}
						className='mx-1'>
						Close
					</Button>

					<Button
						isLight={darkModeStatus}
						icon='Send'
						color='info'
						type='button'
						onClick={formik.handleSubmit}
						className='mx-1'>
						Submit
					</Button>
				</ModalFooter>
			</Modal>

			<LoadingOverlay loading={loading} />
		</div>
	);
};

FormCreateTask.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	onSuccess: PropTypes.func,
	role: PropTypes.string,
	getToken: PropTypes.func,
	detailBoard: PropTypes.oneOf([PropTypes.object]),
	isAuthenticated: PropTypes.bool,
};
FormCreateTask.defaultProps = {
	id: 'formCreateTask',
	title: 'Title',
	size: 'lg',
	onSuccess: () => {},
	role: null,
	getToken: () => {},
	detailBoard: {},
	isAuthenticated: false,
};

export default FormCreateTask;
