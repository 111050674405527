import Service from '../../services/ninda/close-board.service';

const closeBoard = (query) => {
	return Service.closeBoard(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
const needRetro = (query) => {
	return Service.needRetro(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
const createRetrospective = (payload) => {
	return Service.createRetrospective(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default {
	closeBoard,
	createRetrospective,
	needRetro,
};
