import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useDarkMode from '../../../../hooks/useDarkMode';
import BoardModule from '../../../../modules/afif/BoardModule';
import showNotification from '../../../../components/extras/showNotification';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Select from '../../../../components/bootstrap/forms/Select';
import CustomSelect from '../../../../components/custom/Select';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import Label from '../../../../components/bootstrap/forms/Label';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import LoadingOverlay from '../../../bakti/components/LoadingOverlay';

const repeatOptions = [
	{ label: 'Does Not Repeat', value: 'not_repeat' },
	{ label: 'Daily', value: 'daily' },
	{ label: 'Weekly', value: 'weekly' },
	{ label: 'Monthly', value: 'monthly' },
	{ label: 'Annually', value: 'annually' },
];

const reminderOptions = [
	{
		label: 'At Event Time',
		value: 'event_time',
	},
	{
		label: '10 Minutes Before',
		value: '10_min_before',
	},
	{
		label: '1 Hour Before',
		value: '1_hour_before',
	},
	{
		label: '1 Day in Advance',
		value: '1_day',
	},
];

const FormModal = ({
	initialValues,
	boardCode,
	taskCode,
	type,
	isOpen,
	setIsOpen,
	title,
	handleCustomSubmit,
	zIndex,
	getToken,
	detailBoard,
	isAuthenticated,
}) => {
	const { darkModeStatus } = useDarkMode();
	const [withReminders, setWithReminders] = useState(false);
	const [assigneeOptions, setAssigneeOptions] = useState([]);
	const [bearerToken, setBearerToken] = useState(null);
	const { inProgress } = useMsal();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (
			detailBoard?.visibility === 'public' &&
			isOpen &&
			isAuthenticated &&
			inProgress === InteractionStatus.None
		) {
			getToken().then((r) => {
				setBearerToken(r);
			});
		}
		return () => {
			setBearerToken(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailBoard, getToken, isOpen, isAuthenticated, inProgress]);
	const formik = useFormik({
		initialValues: {
			...initialValues,
			repeat: initialValues?.repeat ? initialValues.repeat : repeatOptions[0],
		},
		validationSchema: yup.object({
			title: yup.string().trim().required('required'),
			date: yup
				.string()
				.matches(/^\d{4}-\d{2}-\d{2}$/)
				.required('required')
				.test('today', 'Date must be today or later', (value) => {
					const date = moment(value, 'YYYY-MM-DD');
					return date.isSameOrAfter(currentDate);
				}),
			is_all_day: yup.boolean(),
			time: yup.object().when('is_all_day', {
				is: false,
				then: yup.object({
					start: yup
						.string()
						.required('required')
						.test('current-time', 'Start time must be after current time', () => {
							if (dateSelected.isSame(currentDate)) {
								return startTime.isSameOrAfter(moment().startOf('minute'));
							}
							return true;
						}),
					end: yup
						.string()
						.required('required')
						.test('start-time', 'End time must be after start time', (value) => {
							if (formik.values.time.start && value) {
								const endTime = moment(value, 'HH:mm');

								return endTime.isAfter(startTime);
							}

							return true;
						}),
				}),
			}),
			repeat: yup
				.object({
					label: yup.string().required('required'),
					value: yup.string().required('required'),
				})
				.required('required'),
			reminder: yup.array().when('$withReminders', {
				is: () => withReminders,
				then: yup
					.array(
						yup.object({
							label: yup.string().required('required'),
							value: yup.string().required('required'),
						}),
					)
					.min(1)
					.required('required'),
			}),
			assign_to: yup.array().min(1, 'required'),
			location: yup.string().trim().required('required'),
			description: yup.string().trim().required('required'),
		}),
		onReset: () => {
			setIsOpen(false);
			setLoading(false);
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					cancelButtonText: "Don't Save",
					confirmButtonText: 'Save',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						setLoading(true);
						const newValue = { ...values };
						if (
							detailBoard?.visibility === 'public' &&
							bearerToken &&
							isAuthenticated
						) {
							newValue.bearer_token = bearerToken;
						}
						handleCustomSubmit(
							{
								...newValue,
								reminder: withReminders ? values.reminder.filter(Boolean) : [],
								type,
								parent_code: {
									board_code: boardCode,
									task_code: taskCode,
								},
							},
							formik.handleReset,
						);
					}
				});
			} catch (err) {
				setErrors({ submit: err.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
		enableReinitialize: true,
	});

	const dateSelected = useMemo(
		() => moment(formik?.values?.date, 'YYYY-MM-DD'),
		[formik?.values?.date],
	);
	const currentDate = useMemo(() => moment().startOf('day'), []);
	const startTime = useMemo(
		() => moment(formik?.values?.time?.start, 'HH:mm'),
		[formik?.values?.time?.start],
	);

	useEffect(() => {
		if (initialValues.reminder?.length > 0) {
			setWithReminders(true);
		} else setWithReminders(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	const renderComponent = (
		<FormikProvider value={formik}>
			<form noValidate onSubmit={formik.handleSubmit} className='row px-2'>
				<div className='col-12 mb-3'>
					<FormGroup id='title' label='Title'>
						<Input
							id='InputTitle'
							name='title'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.title}
							isValid={formik.isValid}
							isTouched={formik.touched.title}
							invalidFeedback={formik.errors.title}
						/>
					</FormGroup>
				</div>
				<div className='col-12 mb-3'>
					<FormGroup id='date' label='Date'>
						<Input
							id='InputDate'
							name='date'
							type='date'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.date}
							min={currentDate.format('YYYY-MM-DD')}
							isValid={formik.isValid}
							isTouched={formik.touched.date}
							invalidFeedback={formik.errors.date}
						/>
					</FormGroup>
				</div>
				<div className='col-12 mb-3'>
					<Card shadow='none'>
						<CardHeader className='pb-0' style={{ minHeight: 'unset !important' }}>
							<CardLabel>
								<CardTitle>Time</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody className='row gy-3'>
							<div className='col-12'>
								<Checks
									checked={formik.values.is_all_day}
									onChange={(e) => {
										formik.setFieldValue('is_all_day', e.target.checked);
										formik.setFieldError('time', undefined);
										formik.setFieldTouched('time', {
											start: false,
											end: false,
										});
										formik.setFieldValue('time', {
											start: '',
											end: '',
										});
									}}
									label='All Day'
								/>
							</div>
							<div className='col-6'>
								<FormGroup id='time.start' label='Start'>
									<Input
										id='InputTimeStart'
										type='time'
										autoComplete='off'
										onChange={(e) =>
											formik.setFieldValue('time', {
												...formik.values.time,
												start: e.target.value,
											})
										}
										onBlur={formik.handleBlur}
										value={formik.values.time?.start}
										isValid={formik.isValid}
										isTouched={formik.touched.time?.start}
										invalidFeedback={formik.errors.time?.start}
										disabled={formik.values.is_all_day}
									/>
								</FormGroup>
							</div>
							<div className='col-6'>
								<FormGroup id='time.end' label='End'>
									<Input
										id='InputTimeEnd'
										type='time'
										autoComplete='off'
										onChange={(e) =>
											formik.setFieldValue('time', {
												...formik.values.time,
												end: e.target.value,
											})
										}
										min={formik.values.time?.start}
										onBlur={formik.handleBlur}
										value={formik.values.time?.end}
										isValid={formik.isValid}
										isTouched={formik.touched.time?.end}
										invalidFeedback={formik.errors.time?.end}
										disabled={formik.values.is_all_day}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<Select
									id='SelectRepeat'
									name='repeat'
									list={repeatOptions}
									isValid={formik.isValid}
									isTouched={formik.touched.repeat}
									invalidFeedback={formik.errors.repeat?.label}
									value={formik.values.repeat?.value}
									onChange={(e) => {
										formik.setFieldValue(
											'repeat',
											repeatOptions[e.target.selectedIndex],
										);
									}}
								/>
							</div>
						</CardBody>
					</Card>
				</div>
				<div className='col-12 mb-3'>
					<div className='d-flex'>
						<Checks
							isInline
							id='reminders'
							type='switch'
							checked={withReminders}
							onChange={(e) => setWithReminders(e.target.checked)}
						/>
						<Label className='form-check-inline' htmlFor='reminders'>
							Reminders
						</Label>
					</div>
					{withReminders && (
						<FieldArray
							name='reminder'
							render={({ push, remove }) => (
								<ChecksGroup
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.reminder}
									invalidFeedback={formik.errors.reminder}>
									{reminderOptions.map((reminder) => (
										<Checks
											type='checkbox'
											label={reminder?.label}
											checked={formik.values.reminder?.some(
												(r) => r.value === reminder.value,
											)}
											onChange={(e) => {
												if (e.target.checked) push(reminder);
												else
													remove(
														formik.values.reminder.findIndex(
															(r) => r.value === reminder.value,
														),
													);
											}}
										/>
									))}
								</ChecksGroup>
							)}
						/>
					)}
				</div>
				<div className='col-12 mb-3'>
					<FormGroup id='assign_to' label='Assigned to'>
						<CustomSelect
							id='SelectEmployee'
							options={assigneeOptions}
							isMulti
							isClearable
							isSearchable
							isValid={!formik.errors.assign_to}
							invalidFeedback={formik.errors.assign_to}
							defaultValue={formik.values.assign_to?.map((assignee) => ({
								label: `${assignee.username} - ${assignee.name}`,
								value: assignee.username,
								detail: assignee,
							}))}
							onChange={(options) => {
								formik.setFieldValue(
									'assign_to',
									options.map((option) => option.detail),
								);
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12 mb-3'>
					<FormGroup id='location' label='Location'>
						<Input
							id='InputLocation'
							name='location'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.location}
							isValid={formik.isValid}
							isTouched={formik.touched.location}
							invalidFeedback={formik.errors.location}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='description' label='Description'>
						<Textarea
							id='InputDescription'
							name='description'
							rows={5}
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.description}
							isValid={formik.isValid}
							isTouched={formik.touched.description}
							invalidFeedback={formik.errors.description}
						/>
					</FormGroup>
				</div>
			</form>
		</FormikProvider>
	);

	useEffect(() => {
		if (type === 'board' || type === 'task') {
			if (boardCode) {
				BoardModule.getMemberOptions({ boardCode })
					.then((options) => setAssigneeOptions(options))
					.catch((err) => {
						showNotification('Warning!', err, 'danger');
					});
			}
		}
	}, [type, boardCode]);

	return (
		<>
			<Modal
				titleId={title}
				isOpen={isOpen}
				setIsOpen={formik.handleReset}
				isFocus={false}
				enableEscape={false}
				isCentered
				isStaticBackdrop
				zIndex={zIndex}>
				<ModalHeader setIsOpen={formik.handleReset}>
					<ModalTitle id={title}>{title}</ModalTitle>
				</ModalHeader>
				<ModalBody>{renderComponent}</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isLink
						type='reset'
						onClick={formik.handleReset}
						className='m-1'>
						Close
					</Button>
					<Button
						icon='Save'
						color='info'
						isLight={darkModeStatus}
						type='submit'
						onClick={formik.handleSubmit}
						className='m-1'>
						Save
					</Button>
				</ModalFooter>
			</Modal>
			<LoadingOverlay loading={loading} />
		</>
	);
};
FormModal.propTypes = {
	initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
	boardCode: PropTypes.string.isRequired,
	taskCode: PropTypes.string,
	type: PropTypes.oneOf(['board', 'task']),
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	title: PropTypes.string,
	handleCustomSubmit: PropTypes.func,
	zIndex: PropTypes.number,
	getToken: PropTypes.func,
	detailBoard: PropTypes.oneOf([PropTypes.object]),
	isAuthenticated: PropTypes.bool,
};
FormModal.defaultProps = {
	taskCode: null,
	isOpen: false,
	type: 'board',
	setIsOpen: () => false,
	title: 'Form Modal',
	handleCustomSubmit: () => {},
	zIndex: 1059,
	getToken: () => {},
	detailBoard: {},
	isAuthenticated: false,
};

export default FormModal;
