import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useCookie } from 'react-use';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import Swal from 'sweetalert2';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import PageLayoutHeader from '../../../pages/common/Headers/PageLayoutHeader';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { getRequester, cookieNameGenerator } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import ScheduleModule from '../../../modules/afif/ScheduleModule';
import showNotification from '../../../components/extras/showNotification';
import Item from './components/Item';
import DetailModal from './components/DetailModal';
import FormModal from './components/FormModal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import LoadingModal from '../../../components/custom/LoadingModal';
import BoardRoleModule from '../../../modules/jundi/BoardRoleModule';
import { isBoardRoleAccess } from '../../../helpers/board-role';
import { loginRequest } from '../../../config/authConfig';
import AuthModule from '../../../modules/AuthModule';

const createFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			ScheduleModule.create(values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been created successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const editFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			ScheduleModule.update(values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const deleteFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			Swal.fire({
				heightAuto: false,
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed)
					ScheduleModule.softDelete(values)
						.then((res) => {
							Swal.fire({
								heightAuto: false,
								title: 'Information!',
								text: res?.message ?? 'Data has been deleted successfully',
								icon: 'success',
							});
							resolve({ error: false, message: 'successfully' });
						})
						.catch((err) => {
							Swal.fire({
								heightAuto: false,
								title: 'Warning!',
								text: err,
								icon: 'error',
							});
							reject(new Error(err));
						});
				else reject(new Error('cancelled'));
			});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const FilterCustom = ({ setFilter }) => {
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		initialValues: {
			search: '',
			startDate: null,
			endDate: null,
		},
		onReset: () => {
			setFilter(formik.initialValues);
		},
		onSubmit: (values) => {
			setFilter(values);
		},
	});

	return (
		<>
			<div className='col-auto' style={{ maxWidth: '10rem' }}>
				<FormGroup label='Date Start'>
					<DatePicker
						autoComplete='off'
						wrapperClassName='w-100'
						selected={formik.values.startDate}
						onChange={(e) => {
							formik.setFieldValue('startDate', e);
						}}
						selectsStart
						startDate={formik.values.startDate}
						endDate={formik.values.startDate}
						customInput={<Input type='text' placeholder='dd/mm/yyyy' />}
					/>
				</FormGroup>
			</div>
			<div className='col-auto' style={{ maxWidth: '10rem' }}>
				<FormGroup label='Date End'>
					<DatePicker
						autoComplete='off'
						wrapperClassName='w-100'
						selected={formik.values.endDate}
						onChange={(e) => {
							formik.setFieldValue('endDate', e);
						}}
						selectsEnd
						startDate={formik.values.startDate}
						endDate={formik.values.endDate}
						minDate={formik.values.startDate}
						customInput={<Input type='text' placeholder='dd/mm/yyyy' />}
					/>
				</FormGroup>
			</div>
			<div className='col-auto' style={{ minWidth: '16rem' }}>
				<FormGroup>
					<Input
						name='search'
						autoComplete='off'
						placeholder='Search by Title'
						value={formik.values?.search}
						onKeyUp={(e) => {
							if (e.key === 'Enter') formik.handleSubmit(e);
						}}
						onChange={formik.handleChange}
					/>
				</FormGroup>
			</div>
			<div className='col-auto d-flex'>
				<Button
					icon='Search'
					color='primary'
					type='button'
					isLight={darkModeStatus}
					onClick={formik.handleSubmit}>
					Search
				</Button>
			</div>
			<div className='col-auto d-flex'>
				<Button
					icon='Refresh'
					color='warning'
					type='button'
					isLight={darkModeStatus}
					className='mx-1'
					onClick={formik.handleReset}>
					Reset
				</Button>
			</div>
		</>
	);
};
FilterCustom.propTypes = {
	setFilter: PropTypes.func,
};
FilterCustom.defaultProps = {
	setFilter: () => {},
};

const ButtonSyncOffice = ({ isAuthenticated, handleSyncOffice, windowDimensions }) => {
	return (
		<Button
			disabled={isAuthenticated}
			icon='CustomMicrosoft'
			type='button'
			color='light'
			className='bg-white'
			onClick={() =>
				windowDimensions?.width <= 760
					? handleSyncOffice('redirect')
					: handleSyncOffice('popup')
			}>
			{isAuthenticated ? 'Synced' : 'Sync Office365'}
		</Button>
	);
};

ButtonSyncOffice.propTypes = {
	isAuthenticated: PropTypes.bool,
	windowDimensions: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	handleSyncOffice: PropTypes.func,
};
ButtonSyncOffice.defaultProps = {
	isAuthenticated: false,
	windowDimensions: {
		width: null,
		height: null,
	},
	handleSyncOffice: () => {},
};
const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};
const Schedule = () => {
	const { darkModeStatus } = useDarkMode();
	const { boardCode } = useParams();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [role, setRole] = useState(state?.role);
	const [detailBoard] = useState(state?.detailBoard);
	const { email } = getRequester();

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({});

	const [isActionLoading, setIsActionLoading] = useState(false);
	const [isFormOpen, setIsFormOpen] = useState(false);
	const [formOptions, setFormOptions] = useState({
		initialValues: {},
		title: 'Form Modal',
		handleCustomSubmit: () => {},
	});

	const [selectedItem, setSelectedItem] = useState({});
	const [isDetailOpen, setIsDetailOpen] = useState(false);

	/* Office365 */
	const { instance, inProgress, accounts } = useMsal();
	const [cookieAcc, , removeCookie] = useCookie([cookieNameGenerator('ssoaccount')]);
	const isAuthenticated = useIsAuthenticated();
	const isLNKEmail =
		email?.toLowerCase().includes('lnk.co.id') && email.toLowerCase() !== 'lnk@lnk.co.id';
	const isEmailSSO = cookieAcc ? window.atob(cookieAcc) : null;
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleSyncOffice = (loginType) => {
		if (loginType === 'popup') {
			return instance.loginPopup(loginRequest);
		}
		return instance.loginRedirect(loginRequest);
	};

	const getToken = () => {
		if (
			inProgress === InteractionStatus.None &&
			(isEmailSSO || isLNKEmail) &&
			isAuthenticated
		) {
			const accessTokenRequest = {
				...loginRequest,
				account: accounts[0],
			};

			return instance
				.acquireTokenSilent(accessTokenRequest)
				.then((accessTokenResponse) => {
					// Acquire token silent success
					const { accessToken } = accessTokenResponse;
					return accessToken;
				})
				.catch((error) => {
					if (error instanceof InteractionRequiredAuthError) {
						instance
							.acquireTokenPopup(accessTokenRequest)
							.then(function (accessTokenResponse) {
								// Acquire token interactive success
								const { accessToken } = accessTokenResponse;
								return accessToken;
							})
							// eslint-disable-next-line no-unused-vars
							.catch(function (err) {
								// Acquire token interactive failure

								Swal.fire({
									heightAuto: false,
									title: 'Warning!',
									text: 'Please login outlook first!',
									icon: 'info',
								});
								return { error: true, message: 'Please login outlook first!' };
							});
					}

					Swal.fire({
						heightAuto: false,
						title: 'Warning!',
						text: 'Please login outlook first!',
						icon: 'info',
					});
					return { error: true, message: 'Please login outlook first!' };
				});
		}
		return { error: true };
	};
	const clearMSALToken = () => {
		Swal.fire({
			icon: 'error',
			text: 'Please log in  with your account properly!',
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Relogin Apps',
			denyButtonText: `Relogin Outlook`,
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				AuthModule.logout(removeCookie);
				window.location.href = '/login';
			} else if (result.isDenied) {
				instance.logoutRedirect({
					postLogoutRedirectUri: '/logout',
				});
			}
		});
	};
	useEffect(() => {
		if (isAuthenticated) {
			if (
				email.toLowerCase() !==
				accounts[0]?.idTokenClaims?.preferred_username.toLocaleLowerCase()
			) {
				return clearMSALToken();
			}
		}
		if (
			// uda login sso tapi belum login email di sini
			(!isAuthenticated && isEmailSSO) ||
			// email lnk dan belum login email dan accounts e kosong
			(isLNKEmail && !isAuthenticated && accounts.length === 0)
		) {
			// login
			if (windowDimensions.width <= 760) {
				return handleSyncOffice('redirect');
			}
			return handleSyncOffice('popup');
		}
		return {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instance, accounts, inProgress, cookieAcc, isAuthenticated, isLNKEmail]);

	const fetchData = async () => {
		setIsLoading(true);

		return ScheduleModule.list({
			...filter,
			boardCode,
			groupByMonth: true,
		})
			.then((res) => {
				setData(res.data.filter((d) => d.values.length > 0));
			})
			.catch((err) => {
				showNotification('Warning!', err, 'danger');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleCreate = () => {
		setFormOptions(() => ({
			title: 'Create Schedule',
			initialValues: {
				title: '',
				date: '',
				is_all_day: false,
				time: {
					start: '',
					end: '',
				},
				repeat: null,
				reminder: [],
				assign_to: [],
				location: '',
				description: '',
			},
			handleCustomSubmit: (values, handleReset) => {
				setIsActionLoading(true);
				createFunction(values)
					.then(() => {
						handleReset();
						fetchData();
					})
					.catch(() => {})
					.finally(() => {
						setIsActionLoading(false);
					});
			},
		}));
	};

	const handleEdit = (item) => {
		setFormOptions(() => ({
			title: 'Edit Schedule',
			initialValues: {
				_id: item._id,
				title: item.title,
				date: item.date,
				is_all_day: item.is_all_day,
				time: item.time,
				repeat: item.repeat,
				reminder: item.reminder,
				assign_to: item.assign_to,
				location: item.location,
				description: item?.description,
			},
			handleCustomSubmit: (values, handleReset) => {
				setIsActionLoading(true);
				if (
					detailBoard?.visibility === 'public' &&
					detailBoard?.group_office_id &&
					isAuthenticated
				) {
					getToken()
						.then((res) => {
							const newValues = { ...values, bearer_token: res };
							editFunction(newValues)
								.then(() => {
									handleReset();
									fetchData();
								})
								.catch(() => {})
								.finally(() => {
									setIsActionLoading(false);
								});
						})
						.catch(() => {
							setIsActionLoading(false);
						});
				} else {
					editFunction(values)
						.then(() => {
							handleReset();
							fetchData();
						})
						.catch(() => {})
						.finally(() => {
							setIsActionLoading(false);
						});
				}
			},
		}));
	};

	const handleDetail = (item) => {
		setSelectedItem(() => ({ ...item }));
	};

	const handleDelete = ({ _id }) => {
		setIsActionLoading(true);
		if (
			detailBoard?.visibility === 'public' &&
			detailBoard?.group_office_id &&
			isAuthenticated
		) {
			getToken()
				.then((res) => {
					const newValues = {
						board_code: detailBoard?.trans_code,
						_id,
						bearer_token: res,
					};

					deleteFunction(newValues)
						.then(() => {
							fetchData();
						})
						.catch(() => {
							setIsActionLoading(false);
						});
				})
				.catch(() => {
					setIsActionLoading(false);
				});
		} else {
			deleteFunction({ _id })
				.then(() => {
					fetchData();
				})
				.catch(() => {
					setIsActionLoading(false);
				});
		}
	};

	const fetchRole = useCallback(() => {
		if (role) return;
		BoardRoleModule.getRole({ boardCode }).then(({ data: dataRole }) => {
			setRole(dataRole.role);
		});
	}, [role, boardCode]);

	useEffect(() => {
		if (boardCode) {
			fetchData();
		} else {
			showNotification('Warning!', 'Board code must be specified', 'danger');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		fetchRole();
	}, [fetchRole]);

	useEffect(() => {
		if (formOptions.title !== 'Form Modal') setIsFormOpen(true);
	}, [formOptions]);

	useEffect(() => {
		if (Object.keys(selectedItem).length !== 0) setIsDetailOpen(true);
	}, [selectedItem]);
	return (
		<PageWrapper title='Schedule'>
			<PageLayoutHeader />
			<SubHeader className='row'>
				<SubHeaderLeft className='col-sm'>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back
					</Button>
					<SubheaderSeparator />
					<span className='fw-bold'>Schedule</span>

					{inProgress === InteractionStatus.None &&
						(isEmailSSO || isLNKEmail) &&
						detailBoard?.visibility === 'public' && (
							<div className='col-auto d-flex'>
								<ButtonSyncOffice
									handleSyncOffice={handleSyncOffice}
									windowDimensions={windowDimensions}
									isAuthenticated={isAuthenticated}
								/>
							</div>
						)}
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<LoadingModal isLoading={isActionLoading} setLoading={setIsActionLoading} />
				<DetailModal
					isOpen={isDetailOpen}
					setIsOpen={setIsDetailOpen}
					item={selectedItem}
				/>
				<FormModal
					isOpen={isFormOpen}
					setIsOpen={setIsFormOpen}
					type='board'
					title={formOptions.title}
					initialValues={formOptions.initialValues}
					handleCustomSubmit={formOptions.handleCustomSubmit}
					boardCode={boardCode}
					detailBoard={detailBoard}
					getToken={getToken}
					isAuthenticated={isAuthenticated}
				/>
				<div className='row align-items-end'>
					{isLoading && (
						<div className='col-auto me-3'>
							<Spinner color='info' />
						</div>
					)}
					<FilterCustom setFilter={setFilter} />
					<div className='col'>
						{isBoardRoleAccess(role, ['owner', 'member']) ? (
							<Button
								id='ButtonCreateNew'
								icon='Add'
								type='button'
								color='info'
								isLight={darkModeStatus}
								className='float-end'
								onClick={handleCreate}>
								Create New
							</Button>
						) : null}
					</div>
				</div>
				<div className='row'>
					{data.map((d) => (
						<div key={d.key} className='col-12'>
							<h3 className='fw-bold py-3'>{d.key}</h3>
							{d.values.map((item) => (
								<Item
									key={`${d.key}-Item-${d._id}`}
									item={item}
									handleDetail={handleDetail}
									handleEdit={handleEdit}
									handleDelete={handleDelete}
									role={role}
								/>
							))}
						</div>
					))}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Schedule;
export { createFunction, editFunction, deleteFunction };
