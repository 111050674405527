import axios from 'axios';
import authHeader from '../auth-header';

const findAll = async (query) => {
	return axios.get(`${process.env.REACT_APP_API}board/all`, {
		headers: await authHeader(),
		params: query,
	});
};

const findOneByBoardCode = async (boardCode, query) => {
	return axios.get(`${process.env.REACT_APP_API}board/one/${boardCode}`, {
		headers: await authHeader(),
		params: query,
	});
};

const findOneByBoardKey = async (boardKey, query) => {
	return axios.get(`${process.env.REACT_APP_API}board/one-key/${boardKey}`, {
		headers: await authHeader(),
		params: query,
	});
};

const findDescriptionByBoardCode = async (boardCode) => {
	return axios.get(`${process.env.REACT_APP_API}board/one/${boardCode}/description`, {
		headers: await authHeader(),
	});
};

const findMemberByBoardCode = async (boardCode) => {
	return axios.get(`${process.env.REACT_APP_API}board/one/${boardCode}/member`, {
		headers: await authHeader(),
	});
};

const findColumnByBoardCode = async (boardCode) => {
	return axios.get(`${process.env.REACT_APP_API}board/one/${boardCode}/column`, {
		headers: await authHeader(),
	});
};

const findActivityByBoardCode = async (boardCode) => {
	return axios.get(`${process.env.REACT_APP_API}board/one/${boardCode}/activity`, {
		headers: await authHeader(),
	});
};

const updateOneByBoardCode = async (boardCode, payload) => {
	return axios.patch(`${process.env.REACT_APP_API}board/${boardCode}`, payload, {
		headers: await authHeader(),
	});
};

const closeOneByBoardCode = async (boardCode, payload) => {
	return axios.patch(`${process.env.REACT_APP_API}board/${boardCode}/close`, payload, {
		headers: await authHeader(),
	});
};

const leaveOneByBoardCode = async (boardCode, payload) => {
	return axios.patch(`${process.env.REACT_APP_API}board/${boardCode}/leave`, payload, {
		headers: await authHeader(),
	});
};

const getRules = async (payload) => {
	return axios.post(`${process.env.REACT_APP_API}board/rules`, payload, {
		headers: await authHeader(),
	});
};

export default {
	findAll,
	findOneByBoardCode,
	findDescriptionByBoardCode,
	findMemberByBoardCode,
	findColumnByBoardCode,
	findActivityByBoardCode,
	updateOneByBoardCode,
	closeOneByBoardCode,
	leaveOneByBoardCode,
	getRules,
	findOneByBoardKey,
};
