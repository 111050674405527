import React, { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import classNames from 'classnames';
import MagicUrl from 'quill-magic-url';
import Swal from 'sweetalert2';
import 'react-quill/dist/quill.snow.css';
import './QuillComponent.scss';
import Input from '../../../components/bootstrap/forms/Input';

Quill.register('modules/magicUrl', MagicUrl);
const Link = Quill.import('formats/link');
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

class CustomLinkSanitizer extends Link {
	static sanitize(url) {
		// Run default sanitize method from Quill
		const sanitizedUrl = super.sanitize(url);

		// Not whitelisted URL based on protocol so, let's return `blank`
		if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

		// Verify if the URL already have a whitelisted protocol
		const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol) {
			return sanitizedUrl.startsWith(protocol);
		});

		if (hasWhitelistedProtocol) return sanitizedUrl;

		// if not, then append only 'http' to not to be a relative URL
		return `https://${sanitizedUrl}`;
	}
}

Quill.register(CustomLinkSanitizer, true);

const QuillComponent = ({
	value,
	onChange,
	readOnly,
	textOnly,
	useFocus,
	isFocus,
	setIsFocus,
	className,
}) => {
	const optionsFormats = [];
	const optionsToolbar = [];

	const quillRef = useRef(null);

	if (!textOnly) {
		optionsFormats.push('link', 'image');
		optionsToolbar.push(['link', 'image'], ['clean']);
	}

	const handleQuillBlur = () => {
		if (useFocus && !readOnly && !value && !isFocus) {
			setIsFocus(false);
		}
	};

	const handleInputFocus = () => {
		if (useFocus && !readOnly) setIsFocus(true);
	};
	// Function to handle paste events and sanitize pasted content
	const handlePaste = (evt) => {
		evt.preventDefault();
		const editor = quillRef.current.getEditor();
		const text = evt.clipboardData.getData('text/plain');
		const delta = editor.clipboard.convert(text);
		editor.updateContents(delta, 'user');
	};

	useEffect(() => {
		if (quillRef.current) {
			const editor = quillRef.current.getEditor();
			if (!value) {
				editor.setSelection(0, 0);
			}
			// Add a paste event listener to the Quill editor
			editor.on('paste', handlePaste);
			if (isFocus) {
				editor.focus();
			}
			// Clean up the event listener
			return () => {
				editor.off('paste', handlePaste);
			};
		}
		return () => {};
	}, [isFocus, value]);

	const handleImage = () => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();
		setIsFocus(true);
		input.onchange = async () => {
			const file = input.files[0];
			if (!file.type.startsWith('image/')) {
				Swal.fire({
					heightAuto: false,
					title: 'Warning!',
					text: 'Please select a valid image file!',
					icon: 'error',
				});
				return;
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				const quillEditor = quillRef.current?.getEditor();
				if (!quillEditor) {
					return;
				}
				const imageUrl = reader.result;
				const range = quillEditor.getSelection(true);
				quillEditor.insertEmbed(range.index, 'image', imageUrl, 'user');
				quillEditor.setSelection(range.index + 2, 0);
			};
			reader.readAsDataURL(file);
		};
	};
	const [tool] = useState(
		readOnly
			? false
			: {
					container: [
						[{ header: [1, 2, false] }],
						['bold', 'italic', 'underline', 'strike', 'blockquote'],
						[
							{ list: 'ordered' },
							{ list: 'bullet' },
							{ indent: '-1' },
							{ indent: '+1' },
						],
						...optionsToolbar,
					],
					handlers: {
						image: handleImage,
					},
			  },
	);
	const modules = useMemo(
		() => ({
			clipboard: {
				matchVisual: false, // Preserve formatting from clipboard
			},
			magicUrl: {
				// Regex used to check URLs during typing
				urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
				// Regex used to check URLs on paste
				globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
			},
			toolbar: tool,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[readOnly],
	);
	return (
		<div>
			{isFocus || !useFocus || readOnly ? (
				<ReactQuill
					ref={quillRef}
					theme='snow'
					value={value}
					onChange={onChange}
					modules={modules}
					formats={[
						'header',
						'bold',
						'italic',
						'underline',
						'strike',
						'blockquote',
						'list',
						'bullet',
						'indent',
						...optionsFormats,
					]}
					onBlur={handleQuillBlur}
					readOnly={readOnly}
					className={
						readOnly
							? classNames('custom-editor', 'read-only', className)
							: classNames('custom-editor', className)
					}
					// style={{ minHeight: '60px', height: readOnly && '60px' }}
					placeholder='Write a comment...'
				/>
			) : (
				<Input
					type='text'
					onFocus={handleInputFocus}
					readOnly
					placeholder='Write a comment...'
				/>
			)}
		</div>
	);
};

QuillComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	textOnly: PropTypes.bool,
	useFocus: PropTypes.bool,
	isFocus: PropTypes.bool,
	setIsFocus: PropTypes.func,
	className: PropTypes.string,
};
QuillComponent.defaultProps = {
	value: '',
	onChange: () => '',
	readOnly: false,
	textOnly: false,
	useFocus: false,
	isFocus: false,
	setIsFocus: () => {},
	className: '',
};

export default QuillComponent;
