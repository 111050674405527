import * as React from 'react';

const SvgCustomMicrosoft = (props) => (
	<svg
		width='25'
		height='24'
		viewBox='0 0 25 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		{...props}>
		<rect x='0.5' width='24' height='24' fill='url(#microsoftPattern)' />
		<defs>
			<pattern
				id='microsoftPattern'
				patternContentUnits='objectBoundingBox'
				width='1'
				height='1'>
				<use xlinkHref='#image0_5966_635334' transform='scale(0.01)' />
			</pattern>
			<image
				id='image0_5966_635334'
				width='100'
				height='100'
				xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoUlEQVR4nO3dsW3CUBRAUYSLP4fJIvQgQUV28BRsE9OAmCDxEF4E6F/kJPV3Y/Oj5Fzp9ZafLv8aybBYAAAAAAAAAH+UeH1Zx2t9LDqHuhm7zu1l12wuu2PhWT9hIcNNWUXZqfux69yc9/32so+SMyzFQn6wEIYEQzIwhCHBkAwMYUgwJANDGBIMycAQhgRDMjCEIcGQDAxhSDAkA0MYEgzJwBCGBEMyMIQhwZAMDGFIMCQDQxgSDMnAEIYEQzIwhCHBkAwMYUgwJMP/MeRQN8P7GUXnsLqOXef2vL8OSyk5w0tDsy8EAAAAAJ7Nsr01VfvoS86yvY8+GEaXrvGR+sIz/4Nhdbofq9Mjik77GP0lh68b0qUoPPN/dWIhyUIqhjAkfGQ5Q5whnUNdZU2Nykoqq1JZDAmVpbKcIZ3KUllTo7KSyqpUFkNCZaksZ0inslTW1KispLIqlcWQUFkqyxnSqSyVNTUqK6msSmUxJFSWynKGdCpLZU2Nykq/rLLe7uvvpZSbZXsbfRFmeFlmuCFF5z3N/9erAAAAAAAAwKIMn7VWIgUPaFEZAAAAAElFTkSuQmCC'
			/>
		</defs>
	</svg>
);

export default SvgCustomMicrosoft;
