import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../../views/Login';
import PrivateRoute from '../../components/PrivateRoute';
import Dashboard from '../../views/Dashboard';
import OutstandingDetail from '../../views/bagus/OutstandingDetail';
import PAGE_404 from '../../pages/presentation/auth/Page404';
import { getRouting } from '../../helpers/helpers';
import contents from '../../routes/customRoutes';
import Schedule from '../../views/afif/schedule/Schedule';

import BoardComponent from '../../views/bakti/board';

const menuDB = JSON.parse(localStorage.getItem('menu'));
const presentationDraft = menuDB && getRouting(menuDB);
// get component first
const getComponent =
	presentationDraft &&
	presentationDraft.map((az) => {
		// find component on contents
		const findComponent = contents.filter((ct) => ct.index === az.element);
		const defaultComponent = findComponent.length > 0 ? findComponent[0].element : PAGE_404;
		const objC = { element: defaultComponent, path: az.path };
		return objC;
	});
const presentation =
	getComponent &&
	getComponent.map((it) => {
		const objRet = {
			element: it && it.element ? it.element : null,
			path: it && it.path ? it.path : null,
			key: `${it && it.path}pres`,
		};
		return objRet;
	});

const accessToken = JSON.parse(localStorage.getItem('accessToken'));
const ContentRoutes = () => {
	return (
		<Routes>
			<Route exact path='/login' element={<Login />} />
			<Route
				exact
				path='/home'
				element={
					<PrivateRoute>
						<Dashboard />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path='/detail/:transactionid'
				element={
					<PrivateRoute>
						<OutstandingDetail />
					</PrivateRoute>
				}
			/>

			{/* /boards?board_code=&task_code= */}
			<Route
				exact
				path='/boards'
				element={
					<PrivateRoute>
						<BoardComponent />
					</PrivateRoute>
				}
			/>

			{/* /board/:board_key/task/:task_key */}
			<Route
				exact
				path='/board/:board_key/task/:task_key'
				element={
					<PrivateRoute>
						<BoardComponent />
					</PrivateRoute>
				}
			/>
			{/* /board/:board_key */}
			<Route
				exact
				path='/board/:board_key'
				element={
					<PrivateRoute>
						<BoardComponent />
					</PrivateRoute>
				}
			/>

			<Route
				exact
				path='/schedule/:boardCode'
				element={
					<PrivateRoute>
						<Schedule />
					</PrivateRoute>
				}
			/>
			{accessToken &&
				presentation &&
				presentation?.length > 0 &&
				presentation.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route exact key={page.path} {...page} />
				))}
			<Route
				exact
				path='/'
				element={
					accessToken ? <Navigate to='/home' replace /> : <Navigate to='/login' replace />
				}
			/>
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
