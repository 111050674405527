import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import USERS from '../../../../common/data/userDummyData'; // dummy users
import Card, {
	CardActions,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import Icon from '../../../../components/icon/Icon';
import Avatar from '../../../../components/Avatar';
import Popovers from '../../../../components/bootstrap/Popovers';
import Button from '../../../../components/bootstrap/Button';
import { isBoardRoleAccess } from '../../../../helpers/board-role';

const Item = ({ item, handleDetail, handleEdit, handleDelete, zIndex, role }) => {
	const { date } = useMemo(
		() => ({
			date: moment(item.date),
		}),
		[item],
	);

	return (
		<Card shadow='sm'>
			<CardHeader className='rounded-5'>
				<CardLabel className='w-100' wrapperClassName='row align-items-center w-100'>
					<div className='col-auto text-center'>
						<CardSubTitle tag='h5' className='text-muted'>
							{date.format('ddd')}
						</CardSubTitle>
						<CardTitle className='display-5'>{date.format('DD')}</CardTitle>
					</div>
					<div
						className='col-auto border-end px-2 me-3 opacity-75'
						style={{ height: '5rem' }}
					/>
					<div className='col-auto' style={{ width: '16rem' }}>
						<h6 className='my-1'>
							<Icon icon='AccessTime' color='info' size='lg' />{' '}
							{item.is_all_day ? 'All Day' : `${item.time?.start}-${item.time?.end}`}
						</h6>
						<Popovers desc='Ruang A'>
							<h6 className='my-1'>
								<Icon
									icon='LocationOn'
									color='info'
									size='lg'
									className='text-truncate'
								/>{' '}
								{item.location}
							</h6>
						</Popovers>
					</div>
					<div className='col-auto'>
						<h5>
							[{item.type === 'task' ? 'Task' : 'Board'}] {item.title}
						</h5>
						<Avatar
							src={USERS.GRACE.src}
							srcSet={USERS.GRACE.srcSet}
							color='info'
							userName={item.created_by.name}
							size={30}
						/>
					</div>
				</CardLabel>
				<CardActions>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button icon='MoreHoriz' isLight color='info' />
						</DropdownToggle>
						<DropdownMenu zIndex={zIndex}>
							<DropdownItem>
								<button
									type='button'
									className='text-muted'
									onClick={() => handleDetail(item)}>
									<Icon icon='EyeFill' size='sm' /> Detail
								</button>
							</DropdownItem>
							{isBoardRoleAccess(role, ['owner', 'member']) ? (
								<DropdownItem>
									<button
										type='button'
										className='text-muted'
										onClick={() => handleEdit(item)}>
										<Icon icon='Edit' size='sm' /> Edit
									</button>
								</DropdownItem>
							) : null}

							{isBoardRoleAccess(role, ['owner', 'member']) ? (
								<DropdownItem>
									<button
										type='button'
										className='text-muted'
										onClick={() => handleDelete(item)}>
										<Icon icon='Delete' size='sm' /> Delete
									</button>
								</DropdownItem>
							) : null}
						</DropdownMenu>
					</Dropdown>
				</CardActions>
			</CardHeader>
		</Card>
	);
};
Item.propTypes = {
	item: PropTypes.instanceOf(Object),
	handleDetail: PropTypes.func,
	handleEdit: PropTypes.func,
	handleDelete: PropTypes.func,
	zIndex: PropTypes.number,
	role: PropTypes.string,
};
Item.defaultProps = {
	item: {},
	handleDetail: () => {},
	handleEdit: () => {},
	handleDelete: () => {},
	zIndex: 1057,
	role: null,
};

export default Item;
