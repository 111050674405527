export const isBoardRoleAccess = (role, access = []) => {
	if (!role) return false;

	if (access.includes(role)) return true;
	return false;
};

export default {
	isBoardRoleAccess,
};
