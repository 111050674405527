import Service from '../../services/bakti/user_type.service';

const index = {};

index.read = (query) => {
	return Service.read(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.loadOptions = (search) => {
	return new Promise((resolve) => {
		const query = { search, perPage: 30 };
		Service.read(query)
			.then((response) => {
				resolve(response.data.foundData || []);
			})
			.catch(() => {
				resolve([]);
			});
	});
};

export default index;
