import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import { msalConfig } from './config/authConfig';

const pca = new PublicClientApplication(msalConfig);
ReactDOM.render(
	<Router>
		<React.StrictMode>
			<MsalProvider instance={pca}>
				<ThemeContextProvider>
					<App />
				</ThemeContextProvider>
			</MsalProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
