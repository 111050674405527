import Service from '../services/user.service';

const index = {};

index.getUsers = (query) => {
	return Service.getUsers(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

index.loadOptions = (search, department) => {
	return new Promise((resolve) => {
		const query = { search, perPage: 30 };
		if (department) {
			query.department = department;
		}
		Service.getUsers(query)
			.then((response) => {
				resolve(response.data || []);
			})
			.catch(() => {
				resolve([]);
			});
	});
};

index.getPosition = (query) => {
	return Service.getPosition(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

export default index;
