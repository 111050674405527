import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'board-role';

const getRole = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}`, {
		headers: await authHeader(),
		params: query,
	});
};

export default {
	getRole,
};
