import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'close-board';

const closeBoard = async (payload) => {
	return axios.put(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

const createRetrospective = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}/retrospective`, payload, {
		headers: await authHeader(),
	});
};

const needRetro = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/need-retro/`, {
		headers: await authHeader(),
		params: query,
	});
};

export default {
	closeBoard,
	createRetrospective,
	needRetro,
};
