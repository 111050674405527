import Service from '../../services/bakti/task.service';

const findAllByBoardCode = (boardCode, query) => {
	return Service.findAllByBoardCode(boardCode, query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const findAllTrashByBoardCode = (boardCode, query) => {
	return Service.findAllTrashByBoardCode(boardCode, query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const findOneByTaskCode = (taskCode) => {
	return Service.findOneByTaskCode(taskCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
const findOneByTaskKey = (taskCode) => {
	return Service.findOneByTaskKey(taskCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const create = (boardCode, payload) => {
	return Service.create(boardCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const update = (taskCode, payload) => {
	return Service.update(taskCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const destroy = (taskCode, payload) => {
	return Service.destroy(taskCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const findComment = (taskCode, query) => {
	return Service.findComment(taskCode, query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const createComment = (taskCode, payload) => {
	return Service.createComment(taskCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const updateComment = (id, taskCode, payload) => {
	return Service.updateComment(id, taskCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const deleteComment = (id, taskCode, payload) => {
	return Service.deleteComment(id, taskCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const loadOptionsTags = (search, taskCode) => {
	return new Promise((resolve) => {
		Service.readTags({ search }, taskCode)
			.then((response) => {
				resolve(response.data || []);
			})
			.catch(() => {
				resolve([]);
			});
	});
};

const loadOptionsAllTags = (search, board_code) => {
	return new Promise((resolve) => {
		Service.optionTags(new URLSearchParams({ search, board_code }))
			.then((response) => {
				resolve(response.data || []);
			})
			.catch(() => {
				resolve([]);
			});
	});
};

export default {
	findAllByBoardCode,
	findAllTrashByBoardCode,
	findOneByTaskCode,
	findOneByTaskKey,
	create,
	update,
	destroy,
	findComment,
	createComment,
	updateComment,
	deleteComment,
	loadOptionsTags,
	loadOptionsAllTags,
};
